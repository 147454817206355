import firebase from 'firebase/compat/app'
import "firebase/compat/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAGMdHlxN5gzFy3IWHZxkBKuOugWHtxX8c",
    authDomain: "pmc-paper-world.firebaseapp.com",
    projectId: "pmc-paper-world",
    storageBucket: "pmc-paper-world.appspot.com",
    messagingSenderId: "825152805493",
    appId: "1:825152805493:web:ee4663023cada4ab51f63c"
};

// Get a Firestore instance
const firebaseApp = firebase.initializeApp(firebaseConfig);
export const db = firebaseApp.firestore();

// Export types that exists in Firestore
const { Timestamp } = firebase.firestore;
export { Timestamp };