<template>
    <div id="attendee-form" ref="kiosk">
        <FormTitleScreen    v-if="page===1"
                            @change-page="handlePageChange"
        ></FormTitleScreen>
        <FormUserInput      v-if="page===2"
                            @change-page="handlePageChange"
                            ref="userInput"
        ></FormUserInput>
        <FormModelSelect    v-if="page===3"
                            @change-page="handlePageChange"
                            @selected-model="handleSelectedModel"
                            @selected-color="handleSelectedColor"
        ></FormModelSelect>
        <FormModelPlacement v-if="page===4"
                            @change-page="handlePageChange"
                            :selected-model="selectedModel"
                            :selected-color="selectedColor"
                            :skipUserInfoMode="skipUserInfoMode"
        ></FormModelPlacement>
        <FormThankYou       v-if="page===5"
                            @change-page="handlePageChange"
        ></FormThankYou>
    </div>
</template>

<script>
import FormUserInput from "@/components/FormUserInput";
import FormTitleScreen from "@/components/FormTitleScreen";
import FormModelSelect from "@/components/FormModelSelect";
import FormModelPlacement from "@/components/FormModelPlacement";
import FormThankYou from "@/components/FormThankYou";

export default {
    name: "AttendeeForm",
    components: {
        FormThankYou,
        FormUserInput,
        FormTitleScreen,
        FormModelSelect,
        FormModelPlacement
    },
    props: {
        skipUserInfoMode: null,
    },
    data() {
        return {
            page: 1,
            maxCountdown: 120,
            countdown: 120,
            // selectedModel: '',
            selectedModel: 'crane',
            selectedColor: ''
        }
    },
    methods: {
        handlePageChange(pageNum){
            this.page = pageNum;
        },
        handleSelectedModel(model){
            this.selectedModel = model;
        },
        handleSelectedColor(color){
            this.selectedColor = color;
        },
        resetTimer() {
            this.countdown = this.maxCountdown;
        }
    },
    mounted() {
        document.body.addEventListener('click', this.resetTimer);
        setInterval(()=> { this.countdown = this.countdown - 1}, 1000)
        if (this.skipUserInfoMode){
            this.page = 3
        }
    },
    beforeDestroy: function () {
        document.body.removeEventListener('click', this.resetTimer);
    },
    watch: {
        countdown: {
            handler(value) {
                if (value <= 0) {
                    if (!this.skipUserInfoMode) {
                        this.page = 1;
                    }
                    this.countdown = this.maxCountdown;
                }
            },
            immediate: true // This ensures the watcher is triggered upon creation
        }

    }
}
</script>

<style scoped lang="scss">
    @import "@/assets/scss/variables.scss";

    #attendee-form {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
</style>