<template>
    <div id="mode-selector">
        <h2 class="display-4 white">PMC Paper World</h2>
        <h1 class="display-2 mb-5 blue-primary">Please Select a Mode</h1>
        <div class="buttons">
            <b-button variant="primary" size="lg" class="styled-btn mx-3" @click="$emit('change-mode', 'kiosk')">Kiosk</b-button>
            <b-button variant="primary" size="lg" class="styled-btn mx-3" @click="$emit('change-mode', 'display')">Display</b-button>
            <b-button variant="danger" size="lg" class="styled-btn mx-3" @click="$emit('change-mode', 'admin')">Admin</b-button>
        </div>
    </div>
</template>

<script>
export default {
    name: "ModeSelector"
}
</script>

<style lang="scss" scoped>
    #mode-selector {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .buttons {
        position: relative;
        z-index: 2;
    }
    button {
        cursor: pointer;
    }
</style>