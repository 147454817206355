<template>
    <div class="page-5 page">
        <div class="container-fluid py-8 d-flex justify-content-center align-items-center flex-column">
            <p class="countdown blue-secondary">{{countdown}}</p>
            <h1 class="display-1">Look up!</h1>
            <h2 class="display-2">See your Origami placed on the screen</h2>
            <h3 class="display-3 blue-primary">Thank you for submitting</h3>
        </div>
    </div>
</template>

<script>
export default {
    name: "FormThankYou",
    data() {
        return {
            page: 1,
            maxCountdown: 10,
            countdown: 10,
        }
    },
    mounted() {
        setInterval(()=> { this.countdown = this.countdown - 1}, 1000)
    },
    watch: {
        countdown: {
            handler(value) {
                if (value <= 0) {
                    this.$emit('change-page', 1);
                }
            },
            immediate: true
        }

    }
}
</script>

<style lang="scss" scoped>
    .container-fluid {
        min-height: 100%;
    }
    .countdown {
        position: absolute;
        top: 20px;
        left: 20px;
        font-size: 2rem;
    }
</style>