<template>
    <div class="page-4 page">
        <div class="content-overlay">
            <h1 class="display-2">Place Your Origami</h1>
            <h2>Tap the screen to choose where you'd like to place your origami in PMC's Paper World. </h2>
        </div>

        <b-button variant="secondary" class="styled-btn" @click="submit" :disabled="!isCoordinatesValid">
            <span class="pr-4">Place</span>
            <b-icon icon="arrow-right" animation="cylon" aria-hidden="true"></b-icon>
        </b-button>


        <div class="drag-area" ref="drag">
            <div class="dot-indicator"
                 :style="{top: heightPercent, left: widthPercent}"
                 :class="{ visible: dragIndicatorShown}"
            >
                <img :src="require(`@/assets/img/origami-${selectedModel}.png`)" alt="">
            </div>
        </div>

    </div>
</template>

<script>
import {db, Timestamp} from "@/firebase";

export default {
    name: "FormModelPlacement",
    props: {
        selectedModel: {
            type: String,
            required: true,
        },
        selectedColor: {
            type: String,
            required: true,
        },
        skipUserInfoMode: null,
    },
    data() {
        return {
            coordinates: {
                x: 0,
                y: 0
            },
            documentSize: {
                height: 0,
                width: 0,
            },
            dragIndicatorShown: false,
        }
    },
    mounted() {
        this.$refs.drag.addEventListener('click', this.onClick);
        this.$refs.drag.addEventListener('touchmove', this.onTouch);
        this.documentSize.height = window.innerHeight;
        this.documentSize.width = window.innerWidth;
    },
    beforeDestroy: function () {
        this.$refs.drag.removeEventListener('click', this.onClick);
        this.$refs.drag.removeEventListener('touchmove', this.onTouch);
    },
    methods: {
        onClick(event) {
            if (!this.dragIndicatorShown) {
                this.dragIndicatorShown = true;
            }
            this.coordinates.x = event.clientX;
            this.coordinates.y = event.clientY;
        },
        onTouch(event){
            if (!this.dragIndicatorShown) {
                this.dragIndicatorShown = true;
            }
            this.coordinates.x = event.touches[0].clientX;
            this.coordinates.y = event.touches[0].clientY;
        },
        submit(){
            this.addModel();
            if (this.skipUserInfoMode){
                this.$emit('change-page', 3);
            } else {
                this.$emit('change-page', 5);
            }
        },
        addModel(){
            db.collection('models').add({
                type: this.selectedModel,
                color: this.selectedColor,
                position: {
                    x: (this.coordinates.x / this.documentSize.width).toFixed(5),
                    y: 1-(this.coordinates.y / this.documentSize.height).toFixed(5),
                    z: Math.random(),
                },
                rotation: {
                    x: Math.random(),
                    y: Math.random(),
                    z: Math.random(),
                },
                createdAt: Timestamp.now(),
            })
        },
    },
    computed: {
        heightPercent() {
            return ((this.coordinates.y / this.documentSize.height) * 100).toFixed(2) + '%';
        },
        widthPercent() {
            return ((this.coordinates.x / this.documentSize.width) * 100).toFixed(2) + '%';
        },
        isCoordinatesValid(){
            return this.coordinates.x !== 0 && this.coordinates.y !== 0;
        }
    }
}
</script>

<style scoped lang="scss">
    .page-4 {
        background-image: url('@/assets/img/landscape-cutout.jpg');
        background-size: cover;
        background-position: top center;
    }
    .content-overlay {
        position: relative;
        z-index: 1;
        padding: 6% 8%;
    }
    .drag-area {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        overflow: hidden;

        .dot-indicator {
            position: absolute;
            height: 100px;
            width: 100px;
            transform: translate(-50%, -50%);
            display: none;

            &.visible {
                display: block;
            }

            img {
                object-fit: contain;
                height: 100%;
                width: 100%;
            }
        }
    }
    .styled-btn {
        position: absolute;
        z-index: 3;
        bottom: 6%;
        right: 8%;
    }

    h1 {
        line-height: 1;
    }
    h2 {
        color: #fff;
    }
</style>