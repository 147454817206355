<template>
    <div id="admin">
        <div v-if="!adminAccess" class="password-protect">
            <b-form @submit="onSubmit">
                <h1>Admin Panel</h1>
                <label for="text-password">Access Code</label>
                <b-form-input type="password" v-model="enteredPassword" id="text-password" class="mb-4" autofocus></b-form-input>
                <b-button type="submit" variant="secondary" class="styled-btn mr-3">
                    <span class="pr-4">Enter</span>
                    <b-icon icon="arrow-right" animation="cylon" aria-hidden="true"></b-icon>
                </b-button>
                <b-button variant="danger" @click="$emit('change-mode', 'selector')" class="styled-btn">Go Back</b-button>
            </b-form>
        </div>
        <div v-if="adminAccess" class="container py-6">
            <h1 class="mb-2 display-2 blue-secondary">Admin Panel</h1>
            <b-button variant="primary" @click="$emit('change-mode', 'selector')" class="mb-6">Go Back</b-button>

            <div class="user-data mb-5">
                <h2 class="blue-primary display-4">User Entries</h2>
                <div class="buttons d-flex mb-4">
                    <b-button variant="secondary" @click="showUserData=true" v-if="!showUserData">View Entries</b-button>
                    <b-button variant="secondary" @click="showUserData=false" v-if="showUserData">Hide Entries</b-button>
                    <b-button variant="secondary" @click="downloadCSV">Download CSV</b-button>
                </div>

                <div class="user-entries" v-if="showUserData">
                    <div class="user-entry mb-4"  v-for="(user, index) in userData" :key="index">
                        <h3 class="mb-0">{{user.name.first}} {{user.name.last}}</h3>
                        <a class="mb-0" :href="'mailto:' + user.email">{{user.email}}</a>
                        <p class="mb-0"><span>Industry:</span> {{user.interest}}</p>
                    </div>
                </div>
            </div>

            <div class="controls">
                <h2 class="blue-primary display-4">Controls</h2>

                <h3>Add Random Models</h3>
                <div class="buttons d-flex mb-5">
                    <b-button @click="$emit('addRandomModel', 'dog')">Add Dog</b-button>
                    <b-button @click="$emit('addRandomModel', 'rabbit')">Add Rabbit</b-button>
                    <b-button @click="$emit('addRandomModel', 'crane')">Add Crane</b-button>
                    <b-button @click="$emit('addRandomModel', 'tree')">Add Tree</b-button>
                    <b-button @click="$emit('addRandomModel', 'monkey')">Add Monkey</b-button>
                    <b-button @click="$emit('addRandomModel', 'horse')">Add Horse</b-button>
                </div>

                <h3>Other Controls</h3>
                <div class="buttons d-flex">
                    <b-button @click="$emit('toggleSkipUserInfoMode')"
                              variant="primary"
                              class="toggle-btn"
                              :class="{pressed: this.skipUserInfoMode}"
                    >
                        <span v-if="!skipUserInfoMode">Enable Skip User Info Mode</span>
                        <span v-if="skipUserInfoMode">Disable Skip User Info Mode</span>
                    </b-button>
                    <b-button @click="$emit('clearModelsDB')" variant="danger">Clear Model Database</b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {db} from "@/firebase";

export default {
    name: "AdminMode",
    props: {
        skipUserInfoMode: null,
    },
    data() {
        return {
            userData: [],
            showUserData: false,
            password: 'pmc',
            enteredPassword: '',
            adminAccess: false,
        }
    },
    firestore() {
        return {
            userData: db.collection('UserData').orderBy('createdAt', 'asc'),
        }
    },
    methods: {
        convertToCSV() {
            const headerTitles = [
                'Name',
                'Email',
                'Industry',
                'Added'
            ]

            const headers = headerTitles.join(',');
            let values = [];
            this.userData.forEach( (data) => {
                let array = [
                    data.name.first + ' ' + data.name.last,
                    data.email,
                    data.interest,
                    new Date(data.createdAt.seconds * 1000),
                ]
                values.push(array.join(','))
            })
            return [headers, ...values].join("\n");
        },
        downloadCSV(){
            // console.log(this.convertToCSV())
            const blob = new Blob([this.convertToCSV()], { type: 'text/csv' });

            // Creating an object for downloading url
            const url = window.URL.createObjectURL(blob)

            // Creating an anchor(a) tag of HTML
            const a = document.createElement('a')

            // Passing the blob downloading url
            a.setAttribute('href', url)

            // Setting the anchor tag attribute for downloading and passing the download file name
            a.setAttribute('download', 'paper-world-user-data.csv');

            // Performing a download with click
            a.click()
        },
        onSubmit(event) {
            event.preventDefault();
            if ( this.enteredPassword === this.password ){
                this.adminAccess = true;
            } else {
                this.$emit('change-mode', 'selector');
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    #admin {
        background-color: white;
        min-height: 100vh;
    }

    .password-protect {
        min-height: 100vh;
        padding: 15% 25%;
        display: flex;
        align-items: center;

        form {
            width: 100%;

        }
    }

    .buttons {
        column-gap: 1rem;
    }

    .user-entry {
        span {
            font-weight: bold;
        }
    }

    .toggle-btn {
        background-color: #9F9F9F;

        &.pressed {
            background-color: #007FC6;
        }
    }
</style>