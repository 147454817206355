<template>
    <div class="page-1 page">
        <div class="welcome d-flex justify-content-center align-items-end mb-8">
            <div class="trees">
                <img src="@/assets/img/origami-tree.png" height="874" width="446" alt="Origami Tree">
                <img src="@/assets/img/origami-tree.png" height="874" width="446" alt="Origami Tree">
            </div>
            <h2 class="display-2">Welcome</h2>
        </div>
        <h1 class="display-1">PMC Paper World Experience</h1>
        <b-button variant="secondary" class="mt-8 styled-btn" @click="$emit('change-page', 2)">
            <span class="pr-4">Play</span>
            <b-icon icon="arrow-right" animation="cylon" aria-hidden="true"></b-icon>
        </b-button>
    </div>
</template>

<script>
export default {
    name: "FormTitleScreen"
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";

.page-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.display-1 {
    font-size: 4.5vw;
    margin-bottom: 0;
    padding: 0.2em 0.4em 0.1em;
    $border-width: .075em;
    border-top: $border-width solid $blue-primary;
    border-bottom: $border-width solid $blue-primary;
}

.welcome {
    column-gap: 3em;
    h2 {
        margin-bottom: -.3em;
    }
    .trees {
        display: flex;
        align-items: end;
        img:first-of-type {
            height: 14vh;
            width: auto;
        }
        img:last-of-type {
            //margin-left: -1.5vw;
            margin-left: -3vh;
            height: 8.5vh;
            width: auto;
        }
    }
}

</style>