<template>
    <div class="page-3 page">
        <div class="container-fluid">
            <h1 class="display-2 mb-5">Select an Origami Figure</h1>

            <div id="color-selector">
                <h2>Color:</h2>
                <div class="buttons">
                    <button class="color"
                            v-for="(color, index) in colors"
                            @click="selectedColor = color"
                            :class="{ selected: selectedColor === color }"
                            :style="{ 'background-color': color }"
                            :key="index"
                    ></button>
                </div>
            </div>

            <div class="model-selection">
                <div class="model-option"
                     v-for="(option, index) in options"
                     @click="selectedModel = option"
                     :class="{ active: selectedModel === option}"
                     :key="index"
                >
                    <figure>
                        <img :src="require(`@/assets/img/origami-${option}.png`)" alt="">
                    </figure>
                    <h3>{{option}}</h3>
                </div>
            </div>

            <b-form @submit="onSubmit">
                <b-form-group label="Choose a model">
                    <b-form-radio v-for="(option, index) in options" :value="option" v-model="selectedModel" :key="index">
                        {{option}}
                    </b-form-radio>
                </b-form-group>
                <b-button type="submit" variant="secondary" class="styled-btn" :disabled="!isValid">
                    <span class="pr-4">Next</span>
                    <b-icon icon="arrow-right" animation="cylon" aria-hidden="true"></b-icon>
                </b-button>
            </b-form>
        </div>
    </div>
</template>

<script>

export default {
    name: "FormModelSelect",
    data() {
        return {
            selectedModel: '',
            selectedColor: '#FFFFFF',
            options: [
                'dog',
                'rabbit',
                'crane',
                'tree',
                'monkey',
                'horse'
            ],
            colors: [
                '#FFFFFF',
                '#007FC6',
                '#789179',
                '#e7d899',
            ],
        }
    },
    methods: {
        onSubmit(event) {
            event.preventDefault();
            this.$emit('selected-model', this.selectedModel);
            this.$emit('selected-color', this.selectedColor);
            this.$emit('change-page', 4);
            this.selectedModel = '';
        },
    },
    computed: {
        isValid(){
            return this.selectedModel !== '';
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/variables.scss";

    .page-3 {
        overflow: hidden;
    }

    .container-fluid {
        padding: 6% 8%;
    }

    form {
        position: absolute;
        bottom: 6%;
        right: 8%;
        //transform: translateY(-50%);
    }
    fieldset {
        position: fixed;
        opacity: 0;
        pointer-events: none;
    }

    .model-selection {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        column-gap: 9.5%;
        row-gap: 5vh;
    }
    .model-option {
        background-color: $white;
        padding: 20px;
        cursor: pointer;
        display: flex;
        align-items: end;
        justify-content: end;
        width: 27%;
        height: 28vh;
        position: relative;

        figure {
            position: absolute;
            top: -20px;
            left: -20px;
            width: calc(100% + 40px);
            z-index: 1;
            //height: calc(100% - 40px);
            height: 100%;
            img {
                object-fit: contain;
                height: 100%;
                width: 100%;
            }
        }

        &:hover {
            background-color: $blue-light;
        }

        h1 {
            line-height: 1
        }

        h3 {
            margin-bottom: -.3em;
            color: $blue-dark;
            text-transform: uppercase;
            letter-spacing: 1.5px;
            font-size: 1.75vw;
            position: relative;
            z-index: 2;
        }

        &.active {
            background-color: $blue-primary;

            h3 {
                position: relative;
                color: $white;
            }
        }

    }

    #color-selector {
        position: absolute;
        bottom: 6%;
        left: 8%;

        h2 {
            color: $white;
            font-size: 26px;
        }

        .buttons {
            display: flex;
            column-gap: 20px;
        }

        .color {
            height: 50px;
            width: 50px;
            border: 3px solid transparent;

            &.selected {
                outline: 2px solid $white;
                outline-offset: 4px;
            }
        }
    }
</style>