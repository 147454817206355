<template>
    <div class="page-2 page">
        <div class="container-fluid py-8">
            <div class="row mb-8">
                <div class="col-10">
                    <h1 class="display-2 blue-dark">Let's Continue the Conversation</h1>
                    <p>Let’s talk about sustainability. It sure seems like a buzz word lately, but it is important we continue to talk about it. And not just generally, but focused on solutions, commitments, and innovations that drive awareness - and ultimately change. At Paper Machinery Corporation, we are committed to continuing the conversation around sustainability and we invite you to be a part of it.</p>
                </div>
            </div>
            <div class="row justify-content-between">
                <div class="col-5 rewards d-flex flex-column justify-content-center">
                    <RewardItem :src="require('@/assets/img/airpods.png')">
                        <template v-slot:body>Enter to win one of two pairs of Apple AirPods (3rd generation)</template>
                    </RewardItem>
                </div>
                <div class="col-2 divider-container">
                    <div class="divider"></div>
                </div>
                <div class="col-5 d-flex align-items-center">
                    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                        <b-form-group id="input-group-first-name" label="First Name" label-for="input-first-name">
                            <b-form-input
                                id="input-first-name"
                                v-model.lazy="$v.form.name.first.$model"
                                :state="validateState($v.form.name.first)"
                                placeholder="First name"
                                required
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-first-name-live-feedback">
                                This is a required field.
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group id="input-group-last-name" label="Last Name" label-for="input-last-name">
                            <b-form-input
                                id="input-last-name"
                                v-model.lazy="$v.form.name.last.$model"
                                :state="validateState($v.form.name.last)"
                                placeholder="Last name"
                                required
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-last-name-live-feedback">
                                This is a required field.
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group id="input-group-email" label="Email address" label-for="input-email">
                            <b-form-input
                                id="input-email"
                                v-model.lazy="$v.form.email.$model"
                                :state="validateState($v.form.email)"
                                type="email"
                                placeholder="Enter email"
                                required
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-email-live-feedback">
                                <span v-if="!$v.form.email.required">This is a required field.</span>
                                <span v-else-if="!$v.form.email.email">Must be an email.</span>
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group id="input-group-interest" label="What industry are you in?" label-for="input-3">
                            <b-form-select
                                id="input-3"
                                v-model="$v.form.interest.$model"
                                :state="validateState($v.form.interest)"
                                :options="interests"
                                required
                            ></b-form-select>
                            <b-form-invalid-feedback id="input-interest-live-feedback">
                                This is a required field.
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <b-button type="submit" variant="secondary" :disabled="$v.$invalid" class="styled-btn">
                            <span class="pr-4">Next</span>
                            <b-icon icon="arrow-right" animation="cylon" aria-hidden="true"></b-icon>
                        </b-button>
                    </b-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {db, Timestamp} from "@/firebase";
import RewardItem from "@/components/RewardItem";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

export default {
    name: "FormUserInput",
    components: {RewardItem},
    mixins: [validationMixin],
    data() {
        return {
            form: {
                email: '',
                name: {
                    first: '',
                    last: '',
                },
                interest: null,
            },
            interests: [
                { text: 'Select One', value: null },
                'Processing Equipment',
                'Bagging, Pouching & Wrapping Equipment',
                'Conveying, Feeding & Handling',
                'Coding, Labeling & Printing Equipment',
                'Cartoning, Multipacking & Case Packing',
                'Robot & End-of-Arm Tooling Manufacturer',
                'Specialty Equipment',
                'Material Handling & Warehousing',
                'Converting & Package Forming Equipment',
                'Materials, Containers & Consumables',
                'Controls, Software & Components',
                'Professional & Outside Services',
                'Industry Affiliates',
                'Other',
            ],
            show: true
        }
    },
    validations: {
        form: {
            name: {
                first: {required},
                last: {required},
            },
            email: {
                required,
                email
            },
            interest: {
                required
            },
        }
    },
    destroyed() {
        this.resetForm();
    },
    methods: {
        onSubmit(event) {
            event.preventDefault();

            if (!this.$v.$invalid) {
                let dataToSent = this.form;
                this.$v.$touch()
                dataToSent.createdAt = Timestamp.now();

                db.collection('UserData')
                    .add(dataToSent)
                    .then(this.$emit('change-page', 3))
                    .then(this.resetForm)
            }

        },
        onReset(event) {
            event.preventDefault()
            this.resetForm();
        },
        resetForm(){
            this.form.email = '';
            this.form.name.first = '';
            this.form.name.last = '';
            this.form.interest = null;

            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        validateState(field) {
            return field.$dirty ? !field.$error : null;
        },
    }
}
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/variables.scss";

    .page-2 {
        background-color: rgba(255, 255, 255, 0.65);
        backdrop-filter: grayscale(1) brightness(3.5);
    }
    .container-fluid {
        padding: 6% 8%;
    }
    p {
        font-size: 1.125rem;
    }
    .rewards {
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
    }
    form {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        row-gap: 2.5rem;
        font-size: 1.125rem;

        .form-group {
            margin-bottom: 0;
        }
    }

    .styled-btn {
        width: auto;
    }

    .divider-container {
        position: relative;

        .divider {
            position: absolute;
            content: "";
            height: 100%;
            width: 6px;
            background-color: $blue-secondary;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
        }
    }

    h1 {
        line-height: 1;
    }
</style>