<template>
    <div id="three-canvas" ref="canvas"></div>
</template>

<script>
import * as THREE from 'three';
// import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';
import {TWEEN} from 'three/examples/jsm/libs/tween.module.min'

export default {
    name: 'ThreeCanvas',
    props: {
        firebaseModels: [],
    },
    data() {
        return {
            scene: new THREE.Scene(),
            camera: new THREE.PerspectiveCamera(
                75,
                window.innerWidth / window.innerHeight,
                0.1,
                1000
            ),
            controls: [],
            renderer: new THREE.WebGLRenderer({
                alpha: true,
                antialias: true
            }),
            lights: {
                directionalLight: new THREE.DirectionalLight(0xfffef9, 1.1),
                ambientLight: new THREE.AmbientLight(0xffffff, 0.1),
                rectLight: new THREE.RectAreaLight(0xffffff, 1.5, 200, 400)
            },
            axes: new THREE.AxesHelper(5),
            loader: new GLTFLoader(),
            models: {
                dogs: [],
                rabbits: [],
                cranes: [],
                trees: [],
                monkeys: [],
                horses: [],
            },
            maxCountPerModel: 7,
            count: {
                dogs: 0,
                rabbits: 0,
                cranes: 0,
                trees: 0,
                monkeys: 0,
                horses: 0,
            },
            modelPlacementRange: {
                position: {
                    x: 13,
                    y: 6,
                    z: 20,
                },
                rotation: {
                    x: 0.5,
                    y: 0.75,
                    z: 0.5,
                }
            }
        }
    },
    created() {
        // Set Render size
        this.renderer.setSize(window.innerWidth, window.innerHeight);

        // Shadows
        // this.renderer.shadowMap.enabled = true;
        // this.renderer.shadowMap.type = THREE.PCFShadowMap;


        // Add objects to scene
        this.scene.add(this.camera);
        this.scene.add(this.lights.ambientLight);
        this.scene.add(this.lights.directionalLight);
        this.scene.add(this.lights.rectLight)


        // Set the background to transparent
        this.scene.background = null;


        // Set Positions
        this.lights.directionalLight.position.set(70,100,30)
        // this.lights.directionalLight.castShadow = true;
        // this.lights.directionalLight.shadow.normalBias = 0.1;
        // this.lights.directionalLight.shadow.bias = 0.002;
        this.camera.position.z = 5;
        this.lights.rectLight.position.set( 0, -100, 0)
        this.lights.rectLight.lookAt(0,0,0)


        let directionLightTiming = 10 * 60 * 1000; // 10 Minutes in milliseconds
        let lightColor = new TWEEN.Tween({bColor: 0.95, gColor: 0.975 })
            .to({bColor: 1, gColor: 1}, directionLightTiming/2)
            .onUpdate((coords) => {
                this.lights.directionalLight.color.b = coords.bColor;
                this.lights.directionalLight.color.g = coords.gColor;
                this.lights.rectLight.color.b = coords.bColor;
                this.lights.rectLight.color.g = coords.gColor;
                this.lights.ambientLight.color.b = coords.bColor;
                this.lights.ambientLight.color.g = coords.gColor;
            })
            .repeat(Infinity)
            .yoyo(true)
            .easing(TWEEN.Easing.Cubic.InOut)
        lightColor.start();

        let lightMoveX = new TWEEN.Tween({xPosition: -100 })
            .to({xPosition: 100}, directionLightTiming)
            .onUpdate((coords) => {
                this.lights.directionalLight.position.x = coords.xPosition;
            })
            .repeat(Infinity)
            .yoyo(true)
            .easing(TWEEN.Easing.Cubic.InOut)
        lightMoveX.start();

        let lightMoveY = new TWEEN.Tween({yPosition: 50 })
            .to({yPosition: 200}, directionLightTiming/2)
            .onUpdate((coords) => {
                this.lights.directionalLight.position.y = coords.yPosition;
            })
            .repeat(Infinity)
            .yoyo(true)
            .easing(TWEEN.Easing.Cubic.InOut)
        lightMoveY.start();



        // Fog
        this.scene.fog = new THREE.Fog(0xffffff, -300, 600);


        // Helpers
        // this.scene.add(this.axes);
        // const lightHelper = new THREE.PointLightHelper(this.lights.pointLight);
        // this.scene.add(lightHelper);
        // this.controls = new OrbitControls( this.camera, this.renderer.domElement);


        // Models
        this.addLandscape();
        this.addWindmill(100, -5, 100);
        this.addWindmill(80, 3, 50);

    },
    mounted() {
        this.$refs.canvas.appendChild(this.renderer.domElement);
        this.animate();
    },
    methods: {
        animate(time) {
            requestAnimationFrame(this.animate)
            TWEEN.update(time);
            this.renderer.render(this.scene, this.camera);
        },
        modelTraversing(model, color){
            model.traverse( child => {
                // if ( child.isMesh ) {
                //     child.castShadow = true;
                //     child.receiveShadow = true;
                // }

                if ( child.material ) {
                    child.material = new THREE.MeshStandardMaterial({
                        color: color ? color : '#ffffff',
                        roughness: 1,
                        metalness: 0.15,
                        fog: false,
                    });

                    // Bump Map
                    let bumpMap = new THREE.TextureLoader().load(require('@/assets/img/paper-bump-map.jpg'));
                    bumpMap.wrapS = THREE.RepeatWrapping;
                    bumpMap.wrapT = THREE.RepeatWrapping;
                    bumpMap.repeat.set( 6, 6 );
                    child.material.bumpMap = bumpMap;
                    child.material.bumpScale = 0.01;

                    child.material.side = THREE.DoubleSide;
                    child.material.shadowSide = THREE.DoubleSide
                }
            } );
        },
        setModelPosition(model, position, rotation){
            // Set compensation for Z depth (Due to camera FOV)
            const zDepth = position.z * this.modelPlacementRange.position.z;
            const xDepthCompensation = (zDepth / 5 + 1);
            const yDepthCompensation = (zDepth / 7 + 1);
            const range = {
                x: this.modelPlacementRange.position.x * xDepthCompensation,
                y: this.modelPlacementRange.position.y * yDepthCompensation,
            }

            // Position
            model.position.x = position.x * range.x - (range.x/2);
            model.position.y = position.y * range.y - (range.y/2) ;
            model.position.z = -zDepth;
            // console.log('X Position: ' + model.position.x + ', Y Position: ' + model.position.y + ', Z Position: ' + model.position.z)

            // Random Rotation
            model.rotation.x = rotation.x * this.modelPlacementRange.rotation.x - (this.modelPlacementRange.rotation.x/2);
            model.rotation.y = rotation.y * this.modelPlacementRange.rotation.y - (this.modelPlacementRange.rotation.y/2);
            model.rotation.z = rotation.z * this.modelPlacementRange.rotation.z - (this.modelPlacementRange.rotation.z/2);

        },
        setModelAnimation(model){
            const rotationTiming = {
                x: this.generateRandomNumberBetween(45, 60) * 1000,
                y: this.generateRandomNumberBetween(50, 75) * 1000,
                z: this.generateRandomNumberBetween(50, 75) * 1000,
            }

            let rotateY = new TWEEN.Tween({yRotation: model.rotation.y })
                .to({yRotation: this.trueOrFalse() ? model.rotation.y + Math.PI * 2 : model.rotation.y - Math.PI * 2}, rotationTiming.x)
                .onUpdate((coords) => {
                    model.rotation.y = coords.yRotation;
                })
                .repeat(Infinity)

            let rotateX = new TWEEN.Tween({xRotation: model.rotation.x })
                .to({xRotation: model.rotation.x + Math.PI/5}, rotationTiming.y)
                .onUpdate((coords) => {
                    model.rotation.x = coords.xRotation;
                })
                .repeat(Infinity)
                .yoyo(true)
                .easing(TWEEN.Easing.Cubic.InOut)

            let rotateZ = new TWEEN.Tween({zRotation: model.rotation.z })
                .to({zRotation: model.rotation.z + Math.PI/6}, rotationTiming.z)
                .onUpdate((coords) => {
                    model.rotation.z = coords.zRotation;
                })
                .repeat(Infinity)
                .yoyo(true)
                .easing(TWEEN.Easing.Cubic.InOut)

            rotateY.start();
            rotateZ.start();
            rotateX.start();

        },
        addDog(position, rotation, color){
            this.count.dogs++;

            if (this.models.dogs.length > this.maxCountPerModel) {
                this.removeOldest(this.models.dogs);
            }

            this.loader.load(
                'models/dog.gltf',
                (gltf) => {
                    console.log( "Loaded Dog " + this.count.dogs );

                    this.models.dogs.push(gltf.scene);
                    let dog = this.models.dogs[this.models.dogs.length - 1];
                    dog.name = "dog" + this.count.dogs;
                    this.scene.add( dog );

                    // Set Model Properties
                    this.modelTraversing(dog, color);

                    // Position
                    this.setModelPosition(dog, position, rotation);

                    // Animate Rotation
                    this.setModelAnimation(dog);

                },
                () => {},
                function (error) {
                    console.log( 'Error loading dog' );
                    console.log( error );
                }
            )
        },
        addRabbit(position, rotation, color){
            this.count.rabbits++;

            if (this.models.rabbits.length > this.maxCountPerModel) {
                this.removeOldest(this.models.rabbits);
            }

            this.loader.load(
                'models/rabbit.gltf',
                (gltf) => {
                    console.log( "Loaded Rabbit " + this.count.rabbits );

                    this.models.rabbits.push(gltf.scene);
                    let rabbit = this.models.rabbits[this.models.rabbits.length - 1];
                    rabbit.name = "rabbit" + this.count.rabbits;
                    this.scene.add( rabbit );

                    // Set Model Properties
                    this.modelTraversing(rabbit, color);

                    // Position
                    this.setModelPosition(rabbit, position, rotation);

                    // Animate Rotation
                    this.setModelAnimation(rabbit);

                },
                () => {},
                function (error) {
                    console.log( 'Error loading rabbit' );
                    console.log( error );
                }
            )
        },
        addCrane(position, rotation, color){
            this.count.cranes++;

            if (this.models.cranes.length > this.maxCountPerModel) {
                this.removeOldest(this.models.cranes);
            }

            this.loader.load(
                'models/crane.gltf',
                (gltf) => {
                    console.log( "Loaded Crane " + this.count.cranes );

                    this.models.cranes.push(gltf.scene);
                    let crane = this.models.cranes[this.models.cranes.length - 1];
                    crane.name = "crane" + this.count.cranes;
                    this.scene.add( crane );

                    // Set Model Properties
                    this.modelTraversing(crane, color);

                    // Position
                    this.setModelPosition(crane, position, rotation);

                    // Animate Rotation
                    this.setModelAnimation(crane);

                },
                () => {},
                function (error) {
                    console.log( 'Error loading crane' );
                    console.log( error );
                }
            )
        },
        addTree(position, rotation, color){
            this.count.trees++;

            if (this.models.trees.length > this.maxCountPerModel) {
                this.removeOldest(this.models.trees);
            }

            this.loader.load(
                'models/tree.gltf',
                (gltf) => {
                    console.log( "Loaded Tree " + this.count.trees );

                    this.models.trees.push(gltf.scene);
                    let tree = this.models.trees[this.models.trees.length - 1];
                    tree.name = "tree" + this.count.trees;
                    this.scene.add( tree );

                    // Set Model Properties
                    this.modelTraversing(tree, color);

                    // Position
                    this.setModelPosition(tree, position, rotation);

                    // Animate Rotation
                    this.setModelAnimation(tree);

                },
                () => {},
                function (error) {
                    console.log( 'Error loading tree' );
                    console.log( error );
                }
            )
        },
        addMonkey(position, rotation, color){
            this.count.monkeys++;

            if (this.models.monkeys.length > this.maxCountPerModel) {
                this.removeOldest(this.models.monkeys);
            }

            this.loader.load(
                'models/monkey.gltf',
                (gltf) => {
                    console.log( "Loaded monkey " + this.count.monkeys );

                    this.models.monkeys.push(gltf.scene);
                    let monkey = this.models.monkeys[this.models.monkeys.length - 1];
                    monkey.name = "monkey" + this.count.monkeys;
                    this.scene.add( monkey );

                    // Set Model Properties
                    this.modelTraversing(monkey, color);

                    // Position
                    this.setModelPosition(monkey, position, rotation);

                    // Animate Rotation
                    this.setModelAnimation(monkey);

                },
                () => {},
                function (error) {
                    console.log( 'Error loading monkey' );
                    console.log( error );
                }
            )
        },
        addHorse(position, rotation, color){
            this.count.horses++;

            if (this.models.horses.length > this.maxCountPerModel) {
                this.removeOldest(this.models.horses);
            }

            this.loader.load(
                'models/horse.gltf',
                (gltf) => {
                    console.log( "Loaded Horse " + this.count.horses );

                    this.models.horses.push(gltf.scene);
                    let horse = this.models.horses[this.models.horses.length - 1];
                    horse.name = "horse" + this.count.horses;
                    this.scene.add( horse );

                    // Set Model Properties
                    this.modelTraversing(horse, color);

                    // Position
                    this.setModelPosition(horse, position, rotation);

                    // Animate Rotation
                    this.setModelAnimation(horse);

                },
                () => {},
                function (error) {
                    console.log( 'Error loading horse' );
                    console.log( error );
                }
            )
        },
        addLandscape(){
            this.loader.load(
                'models/landscape.gltf',
                (gltf) => {
                    let landscape = gltf.scene;
                    this.scene.add( landscape );

                    landscape.traverse( child => {
                        if ( child.isMesh ) {
                            child.castShadow = true;
                            child.receiveShadow = true;
                        }
                        if ( child.material ) {
                            child.material.metalness = 0.1
                            child.material.side = THREE.DoubleSide;
                            child.material.receiveShadow = true;
                            child.material.castShadow = true;
                        }
                    } );

                    landscape.rotation.y = Math.PI/2;
                    landscape.position.y = -5
                },
                () => {},
                function (error) {
                    console.log( 'Error Adding Landscape' );
                    console.log( error );
                }
            )
        },
        addWindmill(x, y, z){
            this.loader.load(
                'models/windmill.gltf',
                (gltf) => {
                    let windmill = gltf.scene;
                    this.scene.add( windmill );


                    windmill.traverse( child => {
                        if ( child.isMesh ) {
                            child.castShadow = true;
                        }
                        if ( child.material ) {
                            child.castShadow = true;
                            child.material.metalness = 0.1;
                        }

                        // Windmill Rotation Animation
                        if( child.name === 'motor_2') {
                            let bladeRotation = new TWEEN.Tween({xRotation: 0 })
                                .to({xRotation:  Math.PI*2}, this.generateRandomNumberBetween(4, 8) * 1000)
                                .onUpdate((coords) => {
                                    child.rotation.x = coords.xRotation;
                                })
                                .repeat(Infinity)

                            bladeRotation.start();

                        }
                    } );
                    windmill.castShadow = true;

                    windmill.position.x = x;
                    windmill.position.y = y;
                    windmill.position.z = z;

                    windmill.rotation.y = Math.PI/2;

                },
                () => {},
                function (error) {
                    console.log( 'Error Adding Windmill' );
                    console.log( error );
                }
            )
        },
        removeOldest(modelCollection){
            this.scene.remove(modelCollection[0]);
            modelCollection.shift();
        },
        generateRandomNumberBetween(min, max){
            return Math.floor(Math.random() * (max - min + 1) + min);
        },
        trueOrFalse(){
            return Math.random() > 0.5;
        }
    },
    watch: {
        firebaseModels: {
            handler(){
                if (this.firebaseModels.length){
                    let newestModel = this.firebaseModels[this.firebaseModels.length - 1]

                    switch (newestModel.type){
                        case 'dog':
                            this.addDog(newestModel.position, newestModel.rotation, newestModel.color);
                            break;
                        case 'rabbit':
                            this.addRabbit(newestModel.position, newestModel.rotation, newestModel.color);
                            break;
                        case 'crane':
                            this.addCrane(newestModel.position, newestModel.rotation, newestModel.color);
                            break;
                        case 'tree':
                            this.addTree(newestModel.position, newestModel.rotation, newestModel.color);
                            break;
                        case 'monkey':
                            this.addMonkey(newestModel.position, newestModel.rotation, newestModel.color);
                            break;
                        case 'horse':
                            this.addHorse(newestModel.position, newestModel.rotation, newestModel.color);
                            break;
                        default:
                            console.log('Model has no type');
                            break;
                    }
                }
            },
            deep: true,
        }
    }
}
</script>

<style lang="css">
#three-canvas {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}
</style>
