<template>
    <div class="reward-item">
        <figure>
            <img :src="src" alt="">
        </figure>
        <p>
            <slot name="body"></slot>
        </p>

    </div>
</template>

<script>
export default {
    name: "RewardItem",
    props: {
        src: String
    }
}
</script>

<style lang="scss" scoped>
    .reward-item {
        display: flex;
        align-items: center;
        column-gap: 3vw;
        flex-direction: column;
        text-align: center;
        row-gap: 30px;
    }
    figure {
        height: 25vh;
        width: 15vw;
        min-width: 8vw;
        margin-bottom: 0;

        img {
            object-fit: contain;
            height: 100%;
            width: 100%;
        }
    }
    p {
        font-size: 1.25rem;
        margin-bottom: 0;
        max-width: 75%;
    }
</style>