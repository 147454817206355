<template>
    <div id="app" :class="{ three: mode === 'display'}">
        <ModeSelector v-if="mode==='selector'" @change-mode="handleModeChange"></ModeSelector>
        <AttendeeForm v-if="mode==='kiosk'" :skipUserInfoMode="skipUserInfoMode"></AttendeeForm>
        <AdminMode v-if="mode==='admin'"
                   :skipUserInfoMode="skipUserInfoMode"
                   @change-mode="handleModeChange"
                   @clearModelsDB="clearModelsDB"
                   @addRandomModel="addRandomModel"
                   @toggleSkipUserInfoMode="toggleSkipUserInfoMode"
        ></AdminMode>
        <ThreeCanvas ref="threejs" :firebaseModels="models" v-if="mode==='display'"></ThreeCanvas>
    </div>
</template>

<script>
import ThreeCanvas from './components/ThreeCanvas.vue'
import AttendeeForm from "@/components/AttendeeForm";
import AdminMode from "@/components/AdminMode";
import ModeSelector from "@/components/ModeSelector";
import { db } from './firebase'
import { Timestamp } from './firebase'

export default {
    name: 'App',
    components: {
        ModeSelector,
        AdminMode,
        ThreeCanvas,
        AttendeeForm
    },
    data() {
        return {
            models: [],
            mode: 'selector',
            skipUserInfoMode: false,
        }
    },
    firestore() {
        return {
            models: db.collection('models').orderBy('createdAt', 'asc'),
        }
    },
    mounted() {
        if (this.isIpadOS || this.isIOS) {
            this.mode = 'kiosk';
        }
    },
    methods: {
        addRandomModel(modelType){
            db.collection('models').add({
                type: modelType,
                position: {
                    x: Math.random(),
                    y: Math.random(),
                    z: Math.random(),
                },
                rotation: {
                    x: Math.random(),
                    y: Math.random(),
                    z: Math.random(),
                },
                createdAt: Timestamp.now(),
            })
        },
        clearModelsDB(){
            this.models.forEach( (model) => {
                db.collection('models').doc(model.id).delete();
            })
        },
        handleModeChange(mode){
            this.mode = mode;
        },
        toggleSkipUserInfoMode(){
            this.skipUserInfoMode = !this.skipUserInfoMode;
        }
    },
    computed: {
        isIOS() {
            if (/iPad|iPhone|iPod/.test(navigator.platform)) {
                return true;
            } else {
                return navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform);
            }
        },
        isIpadOS() {
            return navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform);
        }
    },
}
</script>

<style lang="scss">
#app {
    position: relative;
    min-height: 100%;
    background-image: url('./assets/img/blue-paper-texture.jpg');
    background-size: cover;
    background-position: top center;

    &.three {
        //background-image: url('./assets/img/landscape-bg.jpg');
    }
}
body, html {
    height: 100%;
    margin: 0;
}
</style>
